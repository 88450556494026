import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { AppointmentCardProps } from ".";
import { IoIosArrowRoundForward } from "react-icons/io";

const AppointmetCard = ({
  description,
  image,
  bgColor,
  href,
}: AppointmentCardProps) => {
  return (
    <Flex w={"full"} mt={28}>
      <Flex
        bgColor={bgColor}
        justifyContent={["space-around"]}
        alignItems={"center"}
        py={16}
        borderRadius={"15px"}
        mb={"-400px"}
        direction={["column", "row"]}
        zIndex={1}>
        <Box>
          <Image src={image} maxH={["500px", "600px"]} maxW={['100%']} borderRadius={"30px"} />
        </Box>
        <Box w={["90%", "50%"]} color={"white"}>
          <Text fontSize={["28px", "48px"]} fontWeight={"700"} mb={8} mt={[5, 0]} textAlign={['center', 'left']}>
            Faça um agendamento agora
          </Text>
          <Text mb={12} textAlign={['center', 'left']}>{description}</Text>
          <Button
            as='a'
            href={href}
            bgColor={"green.400"}
            color={"white"}
            cursor={'pointer'}
            w={['100%', '25%']}
            onClick={() => {
              window.location.href = "https://wa.me/+5524999257575";
            }}

            rightIcon={<IoIosArrowRoundForward size={"26px"} />}>
            Quero agendar
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

export default AppointmetCard;
