import {
  Box,
  Flex,
  HStack,
  Image,
  IconButton,
  Button,
  useDisclosure,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { NavLink } from "./NavLink";
import React from "react";

const Links = [
  {
    href: "inicio",
    name: "Início",
  },
  {
    href: "nutricionista",
    name: "Nutricionista",
  },
  {
    href: "medico",
    name: "Médico",
  },
  {
    href: "personal",
    name: "Personal Trainer",
  },
  {
    href: "faca-parte",
    name: "Contato",
  },
];

export default function Navbar() {
  const [isFixed, setIsFixed] = React.useState(false);
  const [lastScrollY, setLastScrollY] = React.useState(0);
  const [opacity, setOpacity] = React.useState(1);

  const { isOpen, onOpen, onClose } = useDisclosure();

  React.useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > 60 && currentScrollY < 90) {
        setOpacity(0);
      } else if (currentScrollY >= 90) {
        setIsFixed(true);
        setOpacity(1);
      } else if (currentScrollY < 60 && currentScrollY < lastScrollY) {
        setIsFixed(false);
        setOpacity(1);
        setTimeout(() => setOpacity(1), 300);
      } else {
        setIsFixed(false);
        setOpacity(1);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <Box w={"full"}>
      <Box
        bgColor={"#293533"}
        w='full'
        h={"70px"}
        transition={"all 0.3s ease-in-out"}
        opacity={opacity}
        position={isFixed ? "fixed" : "relative"}
        zIndex={99}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-around"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <Image src={"./logo.svg"}></Image>

          <HStack as={"nav"} spacing={7} display={{ base: "none", md: "flex" }}>
            {Links.map((link) => (
              <NavLink key={link.name} href={link.href}>
                {link.name}
              </NavLink>
            ))}
          </HStack>
          <Flex alignItems={"center"}>
            <Button
              bg={'#7FBF7A'}
              transition={"all 0.3s ease-in-out"}
              _hover={{
                opacity: 0.8,
              }}
              px={8}
              borderRadius={3}
              h={38}
              onClick={() => {
                window.location.href = "https://wa.me/+5524999257575";
              }}
              color={'white'}
              variant={"solid"}>
              QUERO CONSULTAR
            </Button>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box
            pb={4}
            bg={useColorModeValue("white.100", "gray.900")}
            display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4} p={5}>
              {Links.map((link) => (
                <NavLink key={link.name} href={link.href}>
                  {link.name}
                </NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
