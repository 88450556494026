export const professionals = [
  {
    id: "nutricionista",
    sectionTitle: "Conheça Junior Sá",
    sectionDescription: "O nutricionista mais completo da região",
    sectionColor: "#5b7c77",
    contactButton: true,
    contactButtonColor: "#7fbf7a",
    phoneNumber: "5524999257575",
    profileImage: "nutri-profile1.png",
    bookImages: ["img1.jpeg", "img2.jpeg", "img3.jpeg"],
    appointment: {
      description:
        "Chegou a hora de cuidar da sua saúde e alcançar seus objetivos de forma personalizada! Não deixe para depois! Agende agora sua consulta e dê o primeiro passo para transformar sua alimentação e sua vida. Invista em você, com acompanhamento profissional especializado. Marque já e comece sua jornada rumo a uma saúde equilibrada!",
      image: "agende.jpg",
      bgColor: "#293533",
    },
    highlightSection: [
      {
        title: "+2k Clientes",
        subtitle: "Resultado",
        description: "Minha prioridade é entregar resultado e não apenas uma dieta.",
      },
      {
        title: "+7 Anos",
        subtitle: "Experiência",
        description: "A experiência é o alicerce que transforma o conhecimento em resultados.",
      },
      {
        title: "+3 Cidades",
        subtitle: "",
        description:
          "Atendimento em Volta Redonda, Barra do Piraí e Piraí. Atendimento presencial e online.",
      },
    ],
    reviews: [
      
    ],
    reviewsColor: "#567874",
  },
  {
    id: "medico",
    sectionTitle: "Conheça a Giulia",
    sectionDescription: "Equilibrando ciência e estética, promovendo saúde e beleza.",
    sectionColor: "#00658b",
    contactButton: true,
    contactButtonColor: "#aedff7",
    phoneNumber: "5524999257575",
    profileImage: "medic-profile1.png",
    bookImages: [],
    direction: "row",
    appointment: {
      description:
        "Cuide da sua saúde e desempenho com um acompanhamento especializado em saúde cardiovascular, equilíbrio hormonal e performance feminina. Agende agora sua consulta personalizada e comece a transformar sua vida com mais energia, bem-estar e confiança. Invista em você mesma, com quem entende das suas necessidades!",
      image: "jrgiulia.jpg",
      bgColor: "#3682a7",
    },
    highlightSection: [
      {
        title: "Especialista em saúde performance da mulher",
        subtitle: "UNIGUAÇU",
        description: "",
      },
      {
        title: "Especialista em saúde cardiovascular",
        subtitle: "HGCOR-SP",
        description: "",
      },
      {
        title: "+2 Anos como emergencista",
        subtitle: "",
        description:
          "",
      },
    ],
    reviews: [
   
    ],
    reviewsColor: "#003f57",
  },
];
